import { FunctionComponent, useEffect, useState, useReducer} from 'react';
import { getMovementList, MovementInterface, delTransaction } from '../../api/model';
import MovementLeftItem from './movement_left_item';
import MovementRightItem from './movement_right_item';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';



interface MovementListProps {
    month: number,
    year: number,
    updateValue: (value: number) => void,
}
 
const MovementList: FunctionComponent<MovementListProps> = ({month, year, updateValue = () => {}}) => {
    const [mv_list, setMVList] = useState<MovementInterface[]>([]);
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);      
 
    const getRes = async () => {
        const res = await getMovementList(month, year)
        setMVList(res)
    };

    const delTrans = async (value: Uint8Array) => {
        await delTransaction(value)
        forceUpdate()
        updateValue(reducerValue)
    };

    const handleDeleteMovment = (value: Uint8Array) => () => {
       delTrans(value)
    };
    
    useEffect(() => {
         getRes()

    }, [month, reducerValue]);
    return ( 
          <TableContainer component={Paper}  sx={{
        height: 450    
      }}>
        <Table  size="small" aria-label="a dense table"  sx={{
      height: "max-content"
    }}>
            <TableBody>
                {mv_list.map((row) => (
                    <TableRow >
                        <TableCell  style={{ width: 1000 }} ><MovementLeftItem data={row}/></TableCell>
                        <TableCell><MovementRightItem data={row}/></TableCell>
                        <TableCell>
                            <IconButton size={'small'} aria-label="delete" onClick={handleDeleteMovment(row.id)}>
                                <DeleteIcon fontSize={'small'} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>    
    
       </TableContainer>  
    );
}
 
export default MovementList;