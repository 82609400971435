import { FunctionComponent } from 'react';
import { RecurringExpesnsesInterface } from '../../api/model';
import { styled } from '@mui/system';
import { colors } from "../colors";

interface RexLeftItemProps {
    data: RecurringExpesnsesInterface
    
}

const SecondText = styled('text')({
    fontSize: '12px',
    color: colors.gray,
    textAlign: 'left',
})

const NormalText = styled('text')({
    fontSize: '15px',
    color: colors.black,
    textAlign: 'left',
})


const RexLeftItem: FunctionComponent<RexLeftItemProps> = (props) => {
       return ( 
            <>
                <NormalText>{props.data.re_description}</NormalText> <br></br>
             </>

     );
}
 
export default RexLeftItem;