import {FunctionComponent, useEffect, useState} from 'react';
import { Stack } from "@mui/system";
import { colors } from "../components/colors";
import {  Paper, Box } from "@mui/material";
import RexList from '../components/Recursive_expenses/rex_list';

interface RlistProps {
    
}
 
const RList: FunctionComponent<RlistProps> = () => {
    const now = new Date();
    const dd = new Date(now.getFullYear(), now.getMonth(), 1);
    const [new_date, setNewDate] = useState(dd);
    const [month, setMonth] = useState(now.getMonth() + 1);
    const [year, setYear] = useState(now.getFullYear());
    const [current_month_name, setCurrentMonthName] = useState(new_date.toLocaleString('de-DE', { month: 'long' }));

  return (<>
          <Box  sx={{paddingTop: "20px"}}> 
        <Stack spacing={3}>
       
          <Paper elevation={3} >
                <Box sx={{bgcolor: colors.graylight, paddingRight: "10px", borderRadius: "10px"}} > 
                    <Stack direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={3}>
                            <b>{current_month_name} - {year} </b>
                    </Stack>
                </Box>  
            </Paper>
                <RexList month={month}/>
         </Stack>
         </Box>      
    </>  
    );
};
 
export default RList;