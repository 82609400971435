import {FunctionComponent, useEffect, useState} from 'react';
import { Stack } from "@mui/system";
import { colors } from "../components/colors";
import {  Paper, Box } from "@mui/material";
import CCBalance from '../components/ccblance/ccbalance';
import Button from '@mui/material/Button';
import Decrement from '@mui/icons-material/West';
import Increment from '@mui/icons-material/East';


interface CreditCardProp{}

const CreditCard: FunctionComponent<CreditCardProp> = () => {
    const now = new Date();
    const dd = new Date(now.getFullYear(), now.getMonth(), 1);
    const [new_date, setNewDate] = useState(dd);
    const [month, setMonth] = useState(now.getMonth() + 1);
    const [year, setYear] = useState(now.getFullYear());
    const [current_month_name, setCurrentMonthName] = useState(new_date.toLocaleString('de-DE', { month: 'long' }));
    const decrement_month = (): any => {
        const nd = new Date(new_date.setMonth(new_date.getMonth() - 1));
        setNewDate(nd);
        setCurrentMonthName(new_date.toLocaleString('de-DE', { month: 'long' }));
        setYear(new_date.getFullYear());
        setMonth(new_date.getMonth() + 1);
    };

    const increment_month = (): void => {
        const nd = new Date(new_date.setMonth(new_date.getMonth() + 1));
        setNewDate(nd);
        setCurrentMonthName(new_date.toLocaleString('de-DE', { month: 'long' }));
        setYear(new_date.getFullYear());
        setMonth(new_date.getMonth() + 1);
    };
    return (
        <>
          <Box  sx={{paddingTop: "20px"}}> 
            <Stack spacing={3}>
            <Paper elevation={3} >
                <Box sx={{bgcolor: colors.graylight, paddingRight: "10px", borderRadius: "10px"}} > 
                    <Stack direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={3}>
                            <Button variant="outlined" startIcon={<Decrement />} onClick={decrement_month}></Button>
                            <b>{current_month_name} - {year} </b>
                            <Button variant="outlined" startIcon={<Increment />} onClick={increment_month}></Button>
                    </Stack>
                </Box>  
           </Paper>
                <CCBalance month={month} year={year} category="Kereditkarte CartaSI"/>
            </Stack>
         </Box>  
        </>
    );
}

export default CreditCard;
