import { FunctionComponent } from 'react';
import { RecurringExpesnsesInterface } from '../../api/model';
import { styled } from '@mui/system';
import { colors } from "../colors";

interface RexRightItemProps {
    data: RecurringExpesnsesInterface
}

const SecondText = styled('text')({
    fontSize: '12px',
    color: colors.gray,
    textAlign: 'right',
})

const NegativeValueText = styled('text')({
    fontSize: '15px',
    color: colors.primary,
    textAlign: 'right',
})

const PositivValueText = styled('text')({
    fontSize: '15px',
    color: colors.secondary,
    textAlign: 'right',
})

const RexRightItem: FunctionComponent<RexRightItemProps> = (props) => {
    return ( 
            <>
                <PositivValueText>{props.data.re_cash.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</PositivValueText>     
            <br></br>
                <SecondText>{props.data.re_day_of_month}/{props.data.re_month_of_year}</SecondText>
            </>


     );
}
 
export default RexRightItem;