import { FunctionComponent, useEffect} from 'react';
import MovementLeftItem from '../Movement/movement_left_item';
import MovementRightItem from '../Movement/movement_right_item';
import { MovementInterface } from '../../api/model';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface InsertedTransactionsProps {
    data: MovementInterface[]
}



const InsertedTransactions: FunctionComponent<InsertedTransactionsProps> = ({data}) => {
   //  useEffect(() => {}, [data])
     return (<>
             <TableContainer component={Paper}  sx={{
        height: 380    
      }}>
        <Table  size="small" aria-label="a dense table"  sx={{
      height: "max-content"
    }}>
            <TableBody>
                {data.map((row) => (
                    <TableRow >
                        <TableCell  style={{ width: 1000 }} ><MovementLeftItem data={row}/></TableCell>
                        <TableCell><MovementRightItem data={row}/></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>    
    
       </TableContainer>  
    </>  );
}

export default InsertedTransactions;