import { FunctionComponent, useEffect, useState } from "react";
import { GetBalance } from '../../api/model';
import { Grid, Paper, Box } from "@mui/material";
import RegularText from "../texts/RegularText";
import { colors } from "../colors";
import BigText from "../texts/BigText";

interface BalanceViewProp {
    month: number;
    year: number;
    month_name: string;
    force_update_value: number,
}

const BalanceView: FunctionComponent<BalanceViewProp> = ({ month, year, force_update_value }) => {
    const [balance_data, setBalanceData] = useState({formated_balance_in: "0 €", formated_balance_out: "0 €", formated_balance_sum: "0 €", is_negativ: false});
    const getRes = async () => {
        const res = await GetBalance(month, year)
        setBalanceData(res)
    };
     useEffect(() => {
        getRes()
    },[month, force_update_value]);

    return ( 
        <>  
        <Paper elevation={3} >
        <Box sx={{bgcolor: colors.graylight, paddingLeft: "10px", paddingRight: "10px", borderRadius: "10px"}}> 
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <RegularText> Einnahmen </RegularText><br></br>
                    <BigText color="blue">{balance_data.formated_balance_in}</BigText>
                </Grid>
                <Grid item xs={4}>
                <RegularText> Ausgaben </RegularText><br></br>
                <BigText>{balance_data.formated_balance_out}</BigText>
                </Grid>
                <Grid item xs={4}>
                <RegularText> Balance </RegularText><br></br>
                {balance_data.is_negativ ?
                    <BigText fontWeight="bold">{balance_data.formated_balance_sum}</BigText>
                    :
                    <BigText fontWeight="bold" color="blue">{balance_data.formated_balance_sum}</BigText>
            }
                </Grid>

            </Grid>
            </Box>  
           </Paper>
           </>    
     );
}
 
export default BalanceView;