import './App.css';
import { useState, useEffect } from 'react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { RouterProvider, createBrowserRouter, createRoutesFromElements ,Route } from "react-router-dom";
import Home from './screen/Home';
import TForm from './screen/TForm';
import RList from './screen/RList';
import RootLayout from './components/RootLayout';
import Box from '@mui/material/Box';
import Container  from '@mui/material/Container';
import API from './api/ApiClient';
import CreditCard from './screen/CrditCard';
import BalanceListMonth from './screen/BalanceListMonth';
import BalanceListMonthPt from './screen/BalanceListMonthPt';

const router = createBrowserRouter(
  createRoutesFromElements(
     <Route path='/' element={<RootLayout/>}>
      <Route  index element={<Home/>} errorElement={<p>Errore di systema</p>}/>
      <Route  path="/tform" element={<TForm/>} errorElement={<p>Errore di systema</p>}/>
      <Route  path="/rlist" element={<RList/>} errorElement={<p>Errore di systema</p>}/>
      <Route  path="/ccb" element={<CreditCard/>} errorElement={<p>Errore di systema</p>}/> 
      <Route  path="/bl" element={<BalanceListMonth/>} errorElement={<p>Errore di systema</p>}/>  
      <Route  path="/pt" element={<BalanceListMonthPt/>} errorElement={<p>Errore di systema</p>}/>  
    </Route>

  )
);

interface LoginToken {
  email: string;
  jti: string;
  hd: string;
  sub: string;
  family_name: string;
  given_name: string;
}

interface LoginCredential {
  credential: string;
}

function App() {
  const [user, setUser] = useState<LoginToken>({ email: '', jti: '', family_name: '', given_name: '', hd: '', sub: '' });
    const [forbidden, setForbidden] = useState(true);
    const [id_token, setIDToken] = useState(`{"credential" : ""}`);
    const [token, setToken] = useState<LoginCredential>({ credential: '' });

    function handleCredentialResponse(response: any) {
        const userObject = jwt_decode<LoginToken>(response.credential);
        const json_token_id = `{"credential" : "${response.credential}"}`;
        setUser(userObject);

        const customConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `Bearer ${response.credential}` : ''
            }
        };
        const res = API.post(`rcli`, JSON.parse(json_token_id), customConfig)
            .then((res) => {
                sessionStorage.setItem('id_token', JSON.stringify(response.credential));
                setForbidden(false);
            })
            .catch((err) => {
                setForbidden(true);
            })
            .finally();
    }

    useEffect(() => {
        /* global google */

        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse
        });

        google.accounts.id.prompt();
        google.accounts.id.renderButton(document.getElementById('signInDiv'), { theme: 'outline', size: 'large' });
        console.log(forbidden);
    }, [forbidden]);
  return (
    <div className="App">
            {forbidden == false ? (
               <RouterProvider router={router} />
            ) : (
                <Container maxWidth="sm">
                    <Box component="span" sx={{ p: 2 }}>
                        <h1>Login to HB</h1>
                    </Box>
                    <Box component="span" sx={{ p: 2 }}>
                        <div id="signInDiv"></div>
                    </Box>
                </Container>
            )}
       

  </div>
  );
}

export default App;
