
import React, { FunctionComponent } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Euro } from '@mui/icons-material';


 interface TopContentProps {};

 
const TopContent: FunctionComponent<TopContentProps> = (props) => {
    return (    
        <AppBar position="static">
            <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <Euro />
                </IconButton>
                <Typography variant="h6" color="inherit" component="div">
                    HB
                </Typography>
            </Toolbar>
        </AppBar>);
};
 
export default TopContent;
