import { FunctionComponent } from 'react';
import { MovementInterface } from '../../api/model';
import { styled } from '@mui/system';
import { colors } from "../colors";

interface MovementLeftItemProps {
    data: MovementInterface
    
}

const SecondText = styled('text')({
    fontSize: '12px',
    color: colors.gray,
    textAlign: 'left',
})

const NormalText = styled('text')({
    fontSize: '15px',
    color: colors.black,
    textAlign: 'left',
})


const MovementLeftItem: FunctionComponent<MovementLeftItemProps> = (props) => {
    return ( 
            <>
                <NormalText>{props.data.revenue_description}</NormalText> <br></br>
                <SecondText>{props.data.revenue_category} / {props.data.revenue_payment}</SecondText>
            </>


     );
}
 
export default MovementLeftItem;