import { FunctionComponent, useEffect, useState } from "react";
import {CCBalanceInterface, getCCbalance} from '../../api/model';
import { colors } from "../colors";
import { Grid, Paper, Box } from "@mui/material";
import RegularText from "../texts/RegularText";
import BigText from "../texts/BigText";

interface CCBalanceProp {
    month: number,
    year: number,
    category: string,
}

const CCBalance: FunctionComponent<CCBalanceProp> = ({month, year, category}) => {
    console.log("pinco lallino")
    const [ccbalance, setCCBalance] = useState<CCBalanceInterface>({ccbalance: 0.0})
    const getRes = async () => {
        const res = await getCCbalance(month, year, category)
        setCCBalance(res)
    }
    useEffect(() =>{
        getRes()
    },[month]);

return (<>
            <Paper elevation={3}>
              <Box sx={{bgcolor: colors.graylight, paddingLeft: "10px", paddingRight: "10px", borderRadius: "10px"}}> 
                <Grid container spacing={2}>
                <Grid item xs={1}>
                    <RegularText>{category}</RegularText> <br></br>
                    <BigText>{ccbalance.ccbalance}</BigText>
                </Grid>
                </Grid>
              </Box>
            </Paper>
    </>)
}

export default CCBalance;