import axios from 'axios';

//const https = require('https');
//const agent = new https.Agent({ rejectUnauthorized: false });
const client = axios.create({
    //all axios can be used, shown in axios documentation
   // baseURL: 'https://hb-api.yol.cloud',
   // baseURL: 'http://127.0.0.1:8080',
   //  baseURL: 'https://172.27.232.1:65080',
 //   baseURL: 'https://ahb.yol.cloud:65080',
 baseURL: 'https://ahb.yol.cloud:8080',
    responseType: 'json',
    withCredentials: false,
    timeout: 1000
    //headers: { 'Access-Control-Allow-Origin': '*' },
    //httpsAgent: agent
});

export default client;
