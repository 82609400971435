import recat, {FunctionComponent, useEffect, useState} from 'react';
import { BalanceListInterface, getBalaceList } from '../../api/model';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RexLeftItem from './rex_left_item';
import RexRightItem from './rex_right_item';
import RegularText from '../texts/RegularText';


interface BalanceListCompProps {
    month: number;
    year: number;
}
 
const BalanceListComp: FunctionComponent<BalanceListCompProps> = ({month, year}) => {
    const [balList, SetBlList] = useState<BalanceListInterface[]>([])
    const sum = balList.reduce((sum, current) => (sum = sum + current.cash_balance), 0);
  
    const getBList =  async () => {
       const r = await getBalaceList(month, year);
       SetBlList(r);
    }
    
    useEffect(() => {
        getBList()
    }, [month]);

    return ( <>
   
   <TableContainer component={Paper}  sx={{
        height: 450    
      }}>
        <Table  size="small" aria-label="a dense table"  sx={{
      height: "max-content"
    }}>
            <TableBody>
                {balList.map((row) => (
                    <TableRow >
                        <TableCell  style={{ width: 1000 }} ><RexLeftItem data={row}/></TableCell>
                        <TableCell style={{textAlign: 'right'}}><RexRightItem data={row}/></TableCell>
                    </TableRow>
                ))}
                <TableRow>
                <TableCell style={{textAlign: 'right', width: 1000}}>
                      <RegularText fontWeight='bold'> Totale:</RegularText>
                    </TableCell>
                    <TableCell style={{textAlign: 'right'}}>
                      {sum < 0 ? 
                         <RegularText fontWeight="bold" color="red">{sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</RegularText>
                         :
                         <RegularText fontWeight="bold" color="blue">{sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</RegularText>
                      }
                       </TableCell>
                </TableRow>
             </TableBody>
        </Table>    
   
       </TableContainer>  
       </>   
     );
}
 
export default BalanceListComp;