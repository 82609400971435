import { FunctionComponent, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { addTransaction, getCategory, CategoryList, PaymentType, getPaymentTypes } from '../../api/model';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { MovementInterface } from '../../api/model';

interface TransactionFormProps {
    updateTransaction: (value: any) => void,
}
 
const TransactionForm: FunctionComponent<TransactionFormProps> = ({updateTransaction = () => {}}) => {
   const [category_list, setCategoryList] = useState<CategoryList[]>([])
   const [payment_types, setPaymentTypes] = useState<PaymentType[]>([])
    const formik = useFormik({
        initialValues: {
            revenue: 'OUT',
            revenue_description: '',
            revenue_category: '',
            revenue_payment: "",
            revenue_date: '',
            cash: ''
        },
        //      validationSchema: validationSchema,

      
        onSubmit: (values, {resetForm}) => {
            addItem(values)
            resetForm()
        }
    });
    
    const addItem = async (values: any) => {
        const res =  await addTransaction(values)
        if(res != null) {
            updateTransaction(res)
        }
    }

    const getCat = async () => {
        const res = await getCategory()
        setCategoryList(res)
    }
    const getPaymTypes = async () => {
        const res = await getPaymentTypes()
        setPaymentTypes(res)
    }

    useEffect(() =>{
        getCat()
        getPaymTypes()
    },[]);
    return (<>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', p:3,}}>
                 <Grid container spacing={2}>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            type="date"
                            variant="standard"
                            size="small"
                            fullWidth
                            id="revenue_date"
                            name="revenue_date"
                            label="Datum"
                           // defaultValue={formik.values.revenue_date}
                            value={formik.values.revenue_date}
                            onChange={formik.handleChange}
                            
                            />
                            <TextField
                                variant="standard"
                                size="small"
                                fullWidth
                                id="revenue_description"
                                name="revenue_description"
                                label="Beschreibung"
                                value={formik.values.revenue_description}
                                onChange={formik.handleChange}
                            /> 
                            <TextField
                                variant="standard"
                                size="small"
                                fullWidth
                                id="revenue_category"
                                name="revenue_category"
                                label="Kategorie"
                                value={formik.values.revenue_category}
                                onChange={formik.handleChange}
                              select
                                     >
                                        {category_list.map((row) => (
                                            <MenuItem value={row.re_cat_description}>{row.re_cat_description}</MenuItem>
                                        ))}
                            </TextField>
                            <TextField
                                variant="standard"
                                size="small"
                                fullWidth
                                id="revenue_payment"
                                name="revenue_payment"
                                label="Zahlungsart"
                                value={formik.values.revenue_payment}
                                onChange={formik.handleChange}
                              select
                                     >
                                        {payment_types.map((row) => (
                                            <MenuItem value={row.payment_description}>{row.payment_description}</MenuItem>
                                        ))}
                            </TextField>
                            <TextField
                                id="revenue"
                                name="revenue"
                                label="Revenue"
                                fullWidth
                                value={formik.values.revenue}
                                onChange={formik.handleChange}
                                variant="standard"
                                size="small"
                                select
                                    >
                                        <MenuItem value="OUT">Ausgaben</MenuItem>
                                        <MenuItem value="IN">Einnahmen</MenuItem>
                            </TextField>

                            <TextField
                                InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
                                variant="standard"
                                size="small"
                                fullWidth
                                id="cash"
                                name="cash"
                                label="Cash"
                                type={'number'}
                                value={formik.values.cash}
                                onChange={formik.handleChange}
                            />

                            <br></br>
                            <br></br>
                            <Button color="primary" variant="contained" type="submit">
                                Speichern
                            </Button>                                                       
                    </form>
               </Grid>
            </Box>
    </>  );
}
 
export default TransactionForm;