import {FunctionComponent, useEffect, useState} from 'react';
import { RecurringExpesnsesInterface, getReccurentExpenses } from '../../api/model';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RexLeftItem from './rex_left_item';
import RexRightItem from './rex_right_item';
import { TableFooter } from '@mui/material';
import RegularText from '../texts/RegularText';

interface RexListProps {
    month: number
}
 
const RexList: FunctionComponent<RexListProps> = ({month}) => {
    const [re_list, setReList] = useState<RecurringExpesnsesInterface[]>([])
    const sum = re_list.reduce((sum, current) => (sum = sum + current.re_cash), 0);

    const getRList = async () => {
        const res = await getReccurentExpenses(month)
        setReList(res)
    }
    useEffect(() => {
        getRList()
  },[])

  return (<>
  
         <TableContainer component={Paper}  sx={{
        height: 450    
      }}>
        <Table  size="small" aria-label="a dense table"  sx={{
      height: "max-content"
    }}>
            <TableBody>
                {re_list.map((row) => (
                    <TableRow >
                        <TableCell  style={{ width: 1000 }} ><RexLeftItem data={row}/></TableCell>
                        <TableCell><RexRightItem data={row}/></TableCell>
                    </TableRow>
                ))}
                <TableRow style={{textAlign: 'right'}}>
                    <TableCell style={{textAlign: 'right', width: 1000}}>
                      <RegularText fontWeight='bold'> Totale:</RegularText>
                    </TableCell>
                    <TableCell style={{textAlign: 'right'}}>
                       <RegularText fontWeight="bold" color="blue">{sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</RegularText>
                    </TableCell>

                </TableRow>
            </TableBody>
        </Table>    
   
       </TableContainer>  
       </>
  )
};
 
export default RexList;