import { FunctionComponent, useEffect, useState } from "react";
import BalanceView from '../components/balance/balance';
import MovementList from '../components/Movement/movement_list';

import { Stack } from "@mui/system";
import { Swiper} from 'swiper/react';

import { Grid, Paper, Box } from "@mui/material";
import { colors } from "../components/colors";
import Button from '@mui/material/Button';
import Decrement from '@mui/icons-material/West';
import Increment from '@mui/icons-material/East';


interface HomeProps{}
 
const Home: FunctionComponent<HomeProps> = () => {
    const now = new Date();
    const dd = new Date(now.getFullYear(), now.getMonth(), 1);
    const [new_date, setNewDate] = useState(dd);
    const [month, setMonth] = useState(now.getMonth() + 1);
    const [year, setYear] = useState(now.getFullYear());
    const [current_month_name, setCurrentMonthName] = useState(new_date.toLocaleString('de-DE', { month: 'long' }));
    const [force_update_value, setForceUpdateValue] = useState(-1)

    const updateValue = (value: number) :void => {
        setForceUpdateValue(value)
    };

    const decrement_month = (): any => {
        const nd = new Date(new_date.setMonth(new_date.getMonth() - 1));
        setNewDate(nd);
        setCurrentMonthName(new_date.toLocaleString('de-DE', { month: 'long' }));
        setYear(new_date.getFullYear());
        setMonth(new_date.getMonth() + 1);
    };

    const increment_month = (): void => {
        const nd = new Date(new_date.setMonth(new_date.getMonth() + 1));
        setNewDate(nd);
        setCurrentMonthName(new_date.toLocaleString('de-DE', { month: 'long' }));
        setYear(new_date.getFullYear());
        setMonth(new_date.getMonth() + 1);
    };
    useEffect(() => {

    }, [force_update_value])

return ( <>
          <Box  sx={{paddingTop: "20px"}}> 
        <Stack spacing={3}>
       
          <Paper elevation={3} >
                <Box sx={{bgcolor: colors.graylight, paddingRight: "10px", borderRadius: "10px"}} > 
                    <Stack direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={3}>
                            <Button variant="outlined" startIcon={<Decrement />} onClick={decrement_month}></Button>
                            <b>{current_month_name} - {year} </b>
                            <Button variant="outlined" startIcon={<Increment />} onClick={increment_month}></Button>
                    </Stack>
                </Box>  
           </Paper>
           <BalanceView month={month} year={year} month_name={current_month_name} force_update_value={force_update_value}/>
           <MovementList month={month} year={year} updateValue={updateValue}/>
       
         </Stack>
         </Box>      
    </> );
}
export default Home;

/*
export function loader() {
    return  GetBalance(11, 2022);
 }
 */