import { FunctionComponent } from 'react';
import { BalanceListInterface } from '../../api/model';
import { styled } from '@mui/system';
import { colors } from "../colors";
import SmallText from '../texts/SmallText';

interface RexLeftItemProps {
    data: BalanceListInterface
    
}

const SecondText = styled('text')({
    fontSize: '12px',
    color: colors.gray,
    textAlign: 'left',
})

const NormalText = styled('text')({
    fontSize: '15px',
    color: colors.black,
    textAlign: 'left',
})


const RexLeftItem: FunctionComponent<RexLeftItemProps> = (props) => {
       return ( 
            <>
                <NormalText>{props.data.revenue_category}</NormalText> <br></br>
                <SecondText>{props.data.revenue_payment}</SecondText>
             </>

     );
}
 
export default RexLeftItem;