import { red } from '@mui/material/colors';
import { styled } from '@mui/system';
import { FunctionComponent } from "react";
import { colors } from "../colors";


import { TextProps } from './types';

const StyledText = styled('text')({
    fontSize: '20px',
    color: colors.primary,
    textAlign: 'left',
})
 
const BigText: FunctionComponent<TextProps> = (props) => {
    return ( <StyledText sx={{color: props.color, fontSize: props.fontSize, fontWeight: props.fontWeight}} >{props.children}</StyledText> );
}
export default BigText;