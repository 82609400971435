import { FunctionComponent } from 'react';
import { BalanceListBypaymentTypeInterface } from '../../api/model';
import { styled } from '@mui/system';
import { colors } from "../colors";

interface RexRightItemPtProps {
    data: BalanceListBypaymentTypeInterface
}

const SecondText = styled('text')({
    fontSize: '12px',
    color: colors.gray,
    textAlign: 'right',
})

const NegativeValueText = styled('text')({
    fontSize: '15px',
    color: colors.primary,
    textAlign: 'right',
})

const PositivValueText = styled('text')({
    fontSize: '15px',
    color: colors.secondary,
    textAlign: 'right',
})

const RexRightItemPt: FunctionComponent<RexRightItemPtProps> = (props) => {
    return ( 
            <>
               {props.data.cash_balance < 0 ?
                    <NegativeValueText>{props.data.cash_balance?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</NegativeValueText>
                    :
                    <PositivValueText>{props.data.cash_balance.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</PositivValueText>     
                }
         </>
     );
}
 
export default RexRightItemPt;