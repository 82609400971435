import { styled } from '@mui/system';
import { FunctionComponent } from "react";
import { colors } from "../colors";


import { TextProps } from './types';

const StyledText = styled('text')({
    fontSize: '15px',
    color: colors.black,
    textAlign: 'left',
})
 
const RegularText: FunctionComponent<TextProps> = (props) => {
    return ( <StyledText sx={{color: props.color, fontSize: props.fontSize, fontWeight: props.fontWeight}}>{props.children}</StyledText> );
}
 
export default RegularText;