import { FunctionComponent } from 'react';
import { MovementInterface } from '../../api/model';
import { styled } from '@mui/system';
import { colors } from "../colors";

interface MovementRightItemProps {
    data: MovementInterface
}

const SecondText = styled('text')({
    fontSize: '12px',
    color: colors.gray,
    textAlign: 'right',
})

const NegativeValueText = styled('text')({
    fontSize: '15px',
    color: colors.primary,
    textAlign: 'right',
})

const PositivValueText = styled('text')({
    fontSize: '15px',
    color: colors.secondary,
    textAlign: 'right',
})

const MovementRightItem: FunctionComponent<MovementRightItemProps> = (props) => {
    return ( 
            <>
            {props.data.cash < 0?
                <NegativeValueText>{props.data.cash?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</NegativeValueText>
                :
                <PositivValueText>{props.data.cash.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</PositivValueText>     
            }
            <br></br>
            <SecondText>{new Date(props.data.revenue_date)?.toLocaleDateString('de-DE') || ''.split(',')[0]}</SecondText>
            </>


     );
}
 
export default MovementRightItem;