import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import Navigate from '../navigation/BottomNavigation';
import TopContent from '../components/header/TopContent';
import { Container } from "@mui/material";
 
const RootLayout: FunctionComponent = () => {
    return (
        <> 
          <main>
            <TopContent/>
              <Container>
                <Outlet/>
              </Container>
            <Navigate/>
          </main>
        </>
    );
}
 
export default RootLayout;