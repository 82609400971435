import React, { SyntheticEvent, useState } from 'react';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ListIcon from '@mui/icons-material/List';

import { useNavigate } from "react-router-dom";
import LoopIcon from '@mui/icons-material/Loop';


 
const Navigate: React.FunctionComponent = () => {
    const [value, setValue] = useState('home')
    const handlChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }
    const navigate = useNavigate();
    return ( 
        <Paper sx={{position: 'fixed', bottom: 0,  left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
           // sx={{width: '100%', position: 'absolute', bottom: 0}}
            showLabels 
            value={value}
            onChange={handlChange}
        >
            <BottomNavigationAction
                label="Home"
                value="home"
                onClick={() => navigate("/")}
                icon={<HomeIcon />}
            />
               <BottomNavigationAction
                label="Statistik"
                value="pt"
                onClick={() => navigate("/pt")}
                icon={<ListIcon/>}
            />
            <BottomNavigationAction
                label="Statistik"
                value="bl"
                onClick={() => navigate("/bl")}
                icon={<ListIcon/>}
            />
            <BottomNavigationAction
                label="Neue Eingabe"
                value="insert"
                onClick={() => navigate("/tform")}
                icon={<AddIcon/>}
            />
            <BottomNavigationAction
                label="Fixe Ausg."
                value="rlist"
                onClick={() => navigate("/rlist")}
                icon={<LoopIcon/>}
            />

        </BottomNavigation>
        </Paper>

     );
}
 
export default Navigate;
