import API from './ApiClient';
import getSessionStorageOrDefault from './session';

export interface Balance {
    formated_balance_in: string;
    formated_balance_out: string;
    formated_balance_sum: string;
    is_negativ: boolean;
}

export interface MovementInterface {
    id: BigInteger;
    revenue_category: String;
    revenue_payment: String,
    revenue_date: Date;
    revenue: String;
    revenue_description: String;
    cash: number;
}

const defaultValue: MovementInterface = {
    id: new Uint8Array(1),
    revenue_description: '',
    revenue_category: '',
    revenue_payment: '',
    revenue_date: new Date(),
    revenue: '',
    cash: 0,
}

interface BalanceInterface {
    timeframe: String;
    revenue_year: number;
    revenue_month: number;
    revenue: String;
    cash_balance: number;
}

export interface RecurringExpesnsesInterface {
    id: BigInteger;
    re_description: String;
    re_cash: number;
    re_day_of_month: number;
    re_month_of_year: number;
}

export interface CategoryList {
    id: number;
    re_cat_description: string;
}

export interface CCBalanceInterface {
    ccbalance: number;
}

export interface PaymentType {
    id: number;
    payment_description: string;
}

export interface BalanceListInterface {
    cash_balance: number;
    revenue_category: string;
    revenue_payment: string;
    revenue_month: number;
    revenue_year: number;
    id_balance: string;
}

export interface BalanceListBypaymentTypeInterface {
    cash_balance: number;
    revenue_payment: string;
    revenue_month: number;
    revenue_year: number;
    id_balance: string;
}
/*const token = getSessionStorageOrDefault('id_token', false);
    
const customConfig = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: token ? `Bearer ${token}` : ''
    }
};
*/

export async function GetBalance(month: number, year: number) {
    var balance: Balance = {
        formated_balance_in: '0 €',
        formated_balance_out: '0 €',
        formated_balance_sum: '',
        is_negativ: false
    }
    var b_in: number = 0
    var b_out: number = 0
    const token = getSessionStorageOrDefault('id_token', false);
    
    const customConfig = {
        headers: {
          'Content-Type': 'application/json',
           Authorization: token ? `Bearer ${token}` : ''
        }
    };
   const {data, status} =  await API.get(`bl/${month}/${year}`, customConfig)
   const bb: BalanceInterface[] = data
   if (status == 200) {
   bb.map((data) => {
        switch(data.revenue) {
            case 'IN':
              balance.formated_balance_in = data.cash_balance.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }) 
              b_in = data.cash_balance
              break;
            case 'OUT':
                balance.formated_balance_out = data.cash_balance.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }) 
                b_out = data.cash_balance
                break;
        }
   })} else {
    balance.formated_balance_in = b_in.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }) 
    balance.formated_balance_out = b_out.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }) 
   }
   
    const sum: number = b_out + b_in
    if (sum < 0) {
        balance.is_negativ = true
    }
    balance.formated_balance_sum = sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })
    return balance;
} 

export async function getMovementList(month: number, year: number) {
    const token = getSessionStorageOrDefault('id_token', false);
    
const customConfig = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: token ? `Bearer ${token}` : ''
    }
};
   const {data, status} =  await API.get(`mvlistm/${month}/${year}`, customConfig)
   if (status == 200) {
    const list: MovementInterface[] = data
     return list
   } else {
    const NaN: MovementInterface[] = []
    return NaN
   }
}

export async function getCategory() {
    const token = getSessionStorageOrDefault('id_token', false);
    
const customConfig = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: token ? `Bearer ${token}` : ''
    }
};
    const {data, status} = await API.get(`recat`, customConfig)
    if (status == 200 ){
        const list : CategoryList[] = data
        return  data
    } else {
        const NaN: CategoryList[] = []
        return NaN
    }
}

export async function addTransaction(trasaction_data: any) {
    const token = getSessionStorageOrDefault('id_token', false);
    
const customConfig = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: token ? `Bearer ${token}` : ''
    }
};
    const {data, status} =  await API.post(`addmv`, trasaction_data, customConfig)
    if (status == 200) {
        const res: MovementInterface = data
        return res
    } else {
        const res: MovementInterface = defaultValue
        return res
    }
}

export async function delTransaction(value: Uint8Array) {
    const token = getSessionStorageOrDefault('id_token', false);
    
const customConfig = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: token ? `Bearer ${token}` : ''
    }
};
    const {data, status} =  await API.delete(`delmv/${value}`, customConfig)
    if (status == 200) {
        return "OK"
    } else {
        return "Error"
    }
}

export async function getReccurentExpenses(month: number) {
    const token = getSessionStorageOrDefault('id_token', false);
    
    const customConfig = {
        headers: {
          'Content-Type': 'application/json',
           Authorization: token ? `Bearer ${token}` : ''
        }
    };
    const {data, status} = await API.get(`remonth/${month}`, customConfig)
    if (status == 200) {
       const res : RecurringExpesnsesInterface[] = data
       return res
    }  else {
        const res : RecurringExpesnsesInterface[] = []
        return res
    }   
}

export async function getCCbalance(month: number, year: number, category: string) {
    const token = getSessionStorageOrDefault('id_token', false);
    
    const customConfig = {
        headers: {
          'Content-Type': 'application/json',
           Authorization: token ? `Bearer ${token}` : ''
        }
    };
    const {data, status} = await API.get(`ccbal/${month}/${year}/${category}`, customConfig)
    if (status == 200) {
        const res: CCBalanceInterface = data
        return res
    } else {
        const res: CCBalanceInterface = {ccbalance: 0.0}
        return res
    }
}

export async function getBalaceList(month: number, year: number) {
    const token = getSessionStorageOrDefault('id_token', false);
    
    const customConfig = {
        headers: {
          'Content-Type': 'application/json',
           Authorization: token ? `Bearer ${token}` : ''
        }
    };
   
    const {data, status} = await API.get(`ballist/${month}/${year}`, customConfig)
   
    if (status == 200) {
        const res: BalanceListInterface[] = data
        return res
    } else {
        const res: BalanceListInterface[] = []
        return res
    }
}

export async function getPaymentTypes() {
    const token = getSessionStorageOrDefault('id_token', false);
    
    const customConfig = {
        headers: {
          'Content-Type': 'application/json',
           Authorization: token ? `Bearer ${token}` : ''
        }
    };
   
    const {data, status} = await API.get(`tlist`, customConfig)
   
    if (status == 200) {
        const res: PaymentType[] = data
        return res
    } else {
        const res: PaymentType[] = []
        return res
    }
}

export async function getPaymentTypesBalance(month: number, year: number) {
    const token = getSessionStorageOrDefault('id_token', false);
    
    const customConfig = {
        headers: {
          'Content-Type': 'application/json',
           Authorization: token ? `Bearer ${token}` : ''
        }
    };
   
    const {data, status} = await API.get(`balpayt/${month}/${year}`, customConfig)
   
    if (status == 200) {
        const res: BalanceListBypaymentTypeInterface[] = data
        return res
    } else {
        const res: BalanceListBypaymentTypeInterface[] = []
        return res
    }
}


