import { FunctionComponent, useState } from "react";
import TransactionForm from "../components/Transaction_form/transaction_from";
import { colors } from '../components/colors';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack'
import { Container } from "@mui/system";
import { MovementInterface } from '../api/model';
import InsertedTransactions from'../components/Transaction_form/inserted_transactions';


interface TFormProps {
    
}

const TForm: FunctionComponent<TFormProps> = () => {
    const [inserted_transcation, setInsertedTranscation] = useState<MovementInterface[]>([])
 
    const updateTransaction = (value: any) :void => {
        setInsertedTranscation((oldArray) => [...oldArray, value])
    };

   return (<>
    <Box  sx={{paddingTop: "20px"}}> 
       <Stack spacing={3}>
            <Container component={Paper} sx={{backgroundColor: colors.graylight}}>
                <TransactionForm updateTransaction={updateTransaction}/>
            </Container> 
        <InsertedTransactions data={inserted_transcation}/>   
        </Stack>
    </Box>
    </>  );
}
 
export default TForm;
