export const colors = {
    white: "#fff",
    primary: "#ef835d",
    secondary: "#2c365a",
    tertiary: "#85c6d8",
    gray: "#d1d5db",
    graylight: "#F3F4F6",
    graydark: "#4B5563",
    accent: "#fbcd77",
    black: "#000000",
};

export const textColors = {
    white: "#fff",
    primary: "#f2f2f2",
    secondary: "#009900",
    tertiary: "#ccffcc",
    gray: "#d1d5db",
    graylight: "#F3F4F6",
    graydark: "#4B5563",
    accent: "#009900",
    black: "#000000",
};
